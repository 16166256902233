<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person_disease" :person="person_disease && person_disease.person" :disease="person_disease" :monitor_stats="monitor_stats"></PersonProfile>
        </div>
        <TypeBar v-if="person_disease" :showing="show" :person_id="person_disease.person_id" :disease="person_disease" />
        <PersonalHealthInformation></PersonalHealthInformation>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Cms/TypeBar.vue'
import PersonalHealthInformation from '../../components/Diseases/PersonalHealthInformation.vue'
export default {

  name: 'CMSDiseasesHealthInformation',
  components: { PersonProfile, HeaderWindow, TypeBar, PersonalHealthInformation },
  data () {
    return {
      show: 'health-information-listing',
      title: 'Thông tin sức khoẻ cá nhân',
      person_disease: null,
      diseases_id: 0,
      current_page: 1,
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.diseases_id = self.$route?.params?.id
    self.getPersonsDiseasesById()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/admin/care-plan' })
    },
    async getPersonsDiseasesById () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          if (res && res.data) {
            self.person_disease = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf.getRequest('AdminRequest').getSurvivalStatSetting(self.$route?.params?.id, params).then(resp => {
        if (resp && resp.data && resp.data.count) {
          let stats = resp.data.data
          stats.forEach(function (stat, index) {
            if (stat.is_monitor === 1) {
              self.monitor_stats.push(stat)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="css" scoped>
</style>