<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="questionsV2 && questionsV2.name" :show_calll="true" />
        <div class="card radius-10 pt-2 pl-4 pr-4 border-0 mt-2">
          <div style="padding: 55px 25px; min-height: 600px;" v-if="questions && questions.data">
            <div class="form-group mt-3" v-for="(question, index) in questions.data" :key="index">
              <label class="robo-16-400">{{question.content}}</label>
              <pre class="robo-20-500 mt-2">{{answers && person && person.user_id ? getAnswersFormQuestons(answers, person.user_id, question.id) : '--'}}</pre>
            </div>
            <div v-if="questions && questions.count <= 0">
              <div class="text-center robo-20-500">Rỗng</div>
            </div>
          </div>
          <Pagination class="mt-3" v-if="questions && questions.count"  :items="questions" :current_page="current_page" @onRefresh="getQuestions"></Pagination>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
export default {

  name: 'CMSAnswerListing',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      title: '',
      questions: '',
      person_diseases_id: null,
      current_page: 1,
      params: {
        sort: '',
        sort_by: '',
        status: 2
      },
      person: null,
      answers: null,
      is_show: false,
      questionsV2: null
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getQuestions()
    self.getAnswers()
    self.getNameGroupInDetail()
    self.getContactDetail()
  },
  methods: {
    onClose () {
      let self = this
      if (appUtils.isDoctor()) {
        self.$router.push({ path: `/doctor/diseases/${self.$route?.params?.id}/health-information-listing?person=${self.$route.query.person}` })
      } else if (appUtils.isAdmin()) {
        self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/health-information-listing?person=${self.$route.query.person}` })
      }
    },
    async getQuestions () {
      let self = this
      try {
        let res = await self.$rf.getRequest('AuthRequest').getQuestionByGroups(self.$route.query.type, self.params)
        self.questions = res.data
      } catch (e) {
        console.log(e)
      }
    },
    async getAnswers () {
      let self = this
      try {
        var params = {
          sort: 'desc',
          sort_by: 'created_at'
        }
        await self.$rf.getRequest('AuthRequest').getAnswersByGroups(self.$route.query.type, params).then(res => {
          self.is_show = true
          self.answers = res.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getNameGroupInDetail () {
      let self = this
      try {
        let res = await self.$rf.getRequest('AuthRequest').getQuestionByGroupsV2(self.$route.query.type, self.params)
        self.questionsV2 = res.data
      } catch (e) {
        console.log(e)
      }
    },
    getAnswersFormQuestons (answers, user_id, question_id) {
      var result = null
      if (answers) {
        answers.forEach(item => {
          if (item.question_id === question_id && item.user_id === user_id) {
            result = item
          }
        })
      }
      return result && result.answer ? result.answer : '--'
    },
    async getContactDetail () {
      let self = this
      if (self.$route.query.person) {
        try {
          let resp = null
          if (appUtils.isDoctor()) {
            resp = await self.$rf.getRequest('DoctorRequest').getPersonDetail(self.$route.query.person)
            if (resp && resp.data) {
              self.person = resp.data
            }
          } else if (appUtils.isAdmin()) {
            var param = {
              ids: self.$route.query.person
            }
            resp = await self.$rf.getRequest('AdminRequest').getPersons(param)
            if (resp && resp.data && resp.data.data) {
              self.person = resp.data.data
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.file-name {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.file-name p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-rp-w-120 {
  width: 120px;
}
</style>