<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow :title="'Thông tin sức khoẻ'" @onClose="onClose" :type="'cms-health-information'" :disease="disease"></HeaderWindow>
        <div class="mt-3 px-3">
            <CMSInfomationForm :disease="disease" :type="'health-information'"></CMSInfomationForm>
            <ParameterMonitoring v-if="disease" :disease="disease" :is_refresh="is_refresh"></ParameterMonitoring>
        </div>
    </div>
</template>
<script>
import CMSInfomationForm from '../../components/Cms/CMSInfomationForm.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import ParameterMonitoring from '../../components/Diseases/ParameterMonitoring.vue'
export default {

  name: 'CMSHealthInformation',
  components: { HeaderWindow, ParameterMonitoring, CMSInfomationForm },
  data () {
    return {
      disease: null,
      person: null,
      is_refresh: false
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesById()
  },
  methods: {
    onClose () {
      return this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/detail` })
    },
    onRefresh () {
      this.is_refresh = true
      setTimeout(() => {
        this.is_refresh = false
      }, 100)
    },
    async getPersonsDiseasesById () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getPersonsDiseasesById(self.$route?.params?.id).then(res => {
          self.disease = res.data
          self.person = res.data.person || null
        }).then(() => {})
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>